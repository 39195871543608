import React, { ReactElement } from 'react';
import '@GraphQL/fragments';
import * as styles from './index.module.scss';

export interface Props {
	children?: ReactElement | Array<ReactElement>;
}

const Signup = ({ children }: Props): ReactElement => {
	return <div className={styles.root}>{children}</div>;
};

export default Signup;
