import React, { ReactElement, useMemo, useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import cx from 'classnames';
import Input from '@UI/Input';
import BaseButton from '@UI/BaseButton';
import { navigate } from 'gatsby';
import { createUserByEmail } from '../../../services/FirebaseAloadedService';
import * as styles from './index.module.scss';
export interface FormData {
	email: string;
	firstname: string;
	lastname: string;
	company: string;
	selectDummy: string;
}

export interface ResponseData {
	data: {
		inlineMessage: string;
	};
}

export interface Props {
	onResponse?: (value: string) => void;
}

const EmailLeadForm = ({ onResponse }: Props): ReactElement => {
	const [postError, setPostError] = useState<boolean>(false);
	const [submit, setSubmit] = useState(false);
	const [email, setEmail] = useState('');
	const [savedEmail, setSavedEmail] = useState('');
	const [isEmailInvalid, setInvalidEmail] = useState(false);

	//add localStorage into hook to avoid ssr-errors
	useEffect(() => {
		const defaultValue = localStorage.getItem('email') || '';
		setEmail(defaultValue);
		setSavedEmail(defaultValue);
	}, []);

	useEffect(() => {
		savedEmail && localStorage.setItem('email', savedEmail);
	}, [savedEmail]);

	const onUpdate = (value: string) => {
		setEmail(value);
	};

	const onSubmit = (event: any) => {
		event.preventDefault();
		setPostError(false);
		//setEmail(email);
		setSavedEmail(email);
		email &&
			createUserByEmail(email)
				.then(() => {
					setSubmit(true);
					//setSavedEmail(email);
					navigate('/signup/label');
				})
				.catch((e) => {
					setSubmit(true);
					navigate('/signup/label');
					//setSavedEmail(email);
				});
		return false;
	};

	return (
		<div className={cx(styles.root, submit && styles.submit)}>
			<form onSubmit={onSubmit}>
				<div className={styles.field}>
					<Input id="email" inputType="email" value={email} label="E-mail" onUpdate={onUpdate} />
					<span className={styles.error}> {isEmailInvalid ? 'Invalid e-mail address' : ''} </span>
				</div>
				<div className={styles.post}>
					<BaseButton
						className={styles.submitButton}
						buttonType="submit"
						isLoading={submit}
						isConfirm={true}
						link={{
							url: '',
							id: 'submit',
							target: '_modal',
							label: 'Send',
							primary: true
						}}
					/>
					<span className={cx(styles.postError)}>
						{' '}
						{postError && <>Something went wrong sending your request. Please try again later.</>}{' '}
					</span>
				</div>
			</form>
		</div>
	);
};

export default EmailLeadForm;
